<template>
    <v-container>
        <v-row dense justify="space-around">
            <v-card class="mx-auto my-12" width="100%">
                <v-card-title>Informações de atendimento</v-card-title>
                <v-card-text>
                    <v-row dense>

                        <v-col>
                            <v-text-field label="CPF" v-model="cpf" v-mask="'###.###.###-##'" outilened></v-text-field>
                        </v-col>
                        
                        <v-col>
                            <v-btn outilend @click="get_atendimentos()">pesquisasr <v-icon>mdi-magnify</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col >
                            <v-btn outilend :loading="loading_paciente" white :read_only="true">
                               <span v-if="!!this.paciente">
                                    {{ this.paciente[0].nm_paciente }}
                                </span> 
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-tabs grow v-model="tabs">
                    <v-tab key="tab1">Consultas e Cirurgias</v-tab>
                    <v-tab key="tab2">Pequenas cirurgias</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                    <v-tab-item
                        key="tab1"
                    >
                        <v-data-table 
                            :headers="headers" 
                            :items="items" class="elevation-1" 
                            :search="search"
                            :loading="loading"  
                            :single-expand="singleExpand"
                            :expanded.sync="expanded"
                            item-key="cd_atendimento"
                            show-expand
                        >
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" class="py-4 grey lighten-2  text-start">
                                    <v-row dense>
                                        <v-col>
                                            <p v-for="(v,k) in item" :key="k">
                                                {{ k }}: <b>{{ v }}</b>
                                            </p>
                                        </v-col>
                                    </v-row>
                                </td>
                            </template>
                            <template v-slot:top>
                                <v-row dense class="ma-1">
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="5">
                                                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item
                        key="tab2"
                    >
                    <v-data-table 
                            :headers="headers_pq" 
                            :items="pequenos_procedimentos" 
                            class="elevation-1" 
                            :search="search_pq"
                            :loading="loading_peq_proc"  
                        >
                           
                            <template v-slot:top>
                                <v-row dense class="ma-1">
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="5">
                                                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-row>
    </v-container>
</template>    
<script>

import AppEtlService from '@/service/AppEtlService'
const api = new AppEtlService()
const headers = [
    {text:"Cd ate", width:50, value:"cd_atendimento"},
    {text:"Convênio",value:"nm_convenio"},
    {text:"Ori ate",value:"ds_ori_ate"},
    {text:"dt ate",value:"dt_atendimento"},
    {text:"Prestador",value:"nm_prestador"},
    {text:"Tp atex",value:"tp_atendimento"},
    // {text:"sn_retorno",value:"sn_retorno"},
    {text:"cd_pro_int",value:"cd_pro_int"},
    {text:"procedimentos",value:"procedimentos"},
    {text:"empresa",value:"empresa"},
];
const headers_pq = [ 
    {text:'cd_atendimento',value:'cd_atendimento'},
    {text:'cd_paciente',value:'cd_paciente'},
    {text:'realizacao',value:'realizacao'},
    {text:'cd_pro_int',value:'cd_pro_int'}, 
    {text:'ds_ori_ate',value:'ds_ori_ate'},
    {text:'qt_lancamento',value:'qt_lancamento'},
    {text:'ds_ati_med',value:'ds_ati_med'},
    {text:'nm_prestador',value:'nm_prestador'},
];
export default {
    data: () => ({
        tabs:0,
        expanded: [],
        singleExpand: false,
        loading: false,
        loading_paciente: false,
        loading_peq_proc: false,
        search: '',
        search_pq: '',
        cpf: '83141715300',
        items: [],
        headers: headers,
        headers_pq: headers_pq,
        paciente: null,
        pequenos_procedimentos: null
    }), 
    methods:{
        get_atendimentos(){
            this.loading = true
            this.loading_paciente = true
            this.loading_peq_proc = true
            if(this.cpf.length==14){
                let cpf = this.cpf.replace(/\.|-/g, '');
                api.get_query('get_procedimentos',[cpf])
                .then(res=>{
                    this.items = res.data
                    this.get_paciente(cpf)
                    console.log('paciente ',this.paciente)
                })
                .finally(()=>{
                    this.loading = false
                })
            }
        },
        get_paciente(cpf){
            api.get_query('get_pac_nm_cpf',['%',cpf])
            .then(res=>{
                this.paciente = res.data
                this.get_peq_proc(res.data[0].cd_paciente)
            })
            .finally(()=>{this.loading_paciente = false})
        },
        get_peq_proc(cd_paciente){
            
            api.get_query('get_peq_proc',[cd_paciente])
            .then(res=>{
                this.pequenos_procedimentos = res.data
            })
            .finally(()=>{this.loading_peq_proc = false})
        }
    },
    mounted() {
        
    }
}
</script>

<style>
h1 {
  text-align: center;
  color: #396a9f;
}
.my-tooltip-content {
  background-color: red !important;
  color: white !important;
  padding: 10px !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}
</style>